import validator from 'validator';

export class ValidationError extends Error {
  constructor(code, details = {}) {
    super(code);
    this.message = code;
    this.code = code;
    this.details = details;
  }
}

/**
 * Select field value
 * @param {String} field field path
 * @param {Object} data form data
 * @returns {mixed}
 */
export function getFieldValue (field, data) {
  if (!data || !data[field]) {
    return null;
  }

  return data[field];
}

/**
 * Validation: not empty
 * @param {String} field
 * @returns {Function} validator
 */
export function validateNonNull (field) {
  return (data) => {
    var value = getFieldValue(field, data);

    if (!value || value.length < 1) {
      return 'Por favor, preencha este campo'; // new ValidationError('not_empty', { field }); // 'Por favor, preencha este campo'
    }
  };
}

/**
 * Validate match
 * @param {String} fieldA
 * @param {String} fieldB 
 * @returns {Function} validator
 */
export function validateMatch (a, b) {
  return (values) => {
    if (getFieldValue(a, values) === getFieldValue(b, values)) {
      return null;
    }

    return 'As senhas não coincidem'; // new ValidationError('fields_match', { fields: [a, b] });
  }
}

/**
 * Validation: minimum password length
 * @param {String} value
 * @param {Number} min
 * @returns {Function} validator
 */
export const validateCharLength = (field, min) => {
  return (data) => {
    var value = getFieldValue(field, data);

    if (!value || value.length < min) {
      return `Por favor, use ${min} ou mais caracteres`; // new ValidationError('min_length', { field, min });
    }
  
    return null;
  }
}

/**
 * Validattion: email address
 * @param {String} field
 * @returns {Function} validator
 */
export const validateEmailAddress = (field) => {
  return (data) => {
    var value = getFieldValue(field, data);

    if (!value || !validator.isEmail(value)) {
      return 'Informe um endereço de e-mail válido' // new ValidationError('invalid_email_address', { field: field });
    }

    return null;
  }
}

/**
 * Combine validators
 * @param {Array<Function>} validators 
 * @returns {Function} validator
 */
export const combineValidators = (validators) => {
  return (data) => {
    var errors = [];

    validators.forEach((validator) => {
      var error = validator(data);

      if (error) {
        if (Array.isArray(error)) {
          error.forEach(errors.push);
        } else {
          errors.push(error);
        }
      }
    });

    if (errors.length === 0) {
      return null;
    }

    return errors;
  }
}

/**
 * Validate form
 * @param {Object} validators 
 * @param {Object} values
 * @returns {Object} form
 */
export const validateForm = (validators) => {
  return (values) => {
    var buffer = {};

    Object.keys(validators).forEach((field) => {
      var validator = validators[field];
      var errors = validator(values);

      if (errors) {
        buffer[field] = errors;
      }
    });

    return buffer;
  }
}

export default {
  ValidationError,
  validateNonNull,
  validateMatch, 
  validateCharLength,
  validateEmailAddress,
  validateForm,
  combineValidators
}