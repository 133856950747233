import { Auth, Hub } from 'aws-amplify';

export default async ({ payload }) => {
  var response = {    
    error: null,
    data: null,
    form: {
      email: payload.email,
      name: payload.name
    }
  };

  try {
    var signUpParams = {
      username: payload.email,
      password: payload.password,
      attributes: {
        email: payload.email,
        name: payload.name
      }
    };

    var signUpResponse = await Auth.signUp(signUpParams);
    response.data = signUpResponse;
  } catch (e) {
    response.error = e;
  }

  Hub.dispatch('register_account_response', response);
}