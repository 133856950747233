const DEFAULT_STATE = {
  loading: false,
  errors: [],
  data: {
    name: '',
    username: '', // email
    password: '',
    passwordConfirm: '',
    agreedTerms: false
  }
}

function signUpReducer (state = DEFAULT_STATE, action) {
  return state;
}

export default signUpReducer;