import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";

// Pages
import IndexPage from './pages/Home';
import SignInPage from './pages/SignIn';
import SignUpPage from './pages/SignUp';
import SignOutPage from './pages/SignOut';

import OAuth2Page from './pages/OAuth';
import InvitationPage from './pages/Invitation';
import ConfirmPage from './pages/Confirm';

const Routes = (props) => {
  return (
    <Router>
      <Route path="/" exact component={IndexPage} />
      <Route path="/sign-in" exact component={SignInPage} />
      <Route path="/sign-up" exact component={SignUpPage} />
      <Route path="/sign-out" exact component={SignOutPage} />
      <Route path="/oauth" exact component={OAuth2Page} />
      <Route path="/invitation/:id" exact component={InvitationPage} />
      <Route path="/confirm" exact component={ConfirmPage} />
    </Router>
  )
}

export default Routes;