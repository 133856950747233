import { useSelector } from 'react-redux';

const getSignedUserSelector = ({ user }) => {
  if (user) {
    return {
      isTouched: user.touched,
      isLoading: user.loading,
      isConnected: user.connected,
      data: user.data
    }
  }

  return user;
}

const useSignedUser = () => {
  //const [ signedUser ] = useSelector(getSignedUserSelector);
  const signedUser = useSelector(getSignedUserSelector);
  return signedUser;
}

export default useSignedUser;