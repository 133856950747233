const DEFAULT_STATE = {
  inviteByCode: {}
}

/**
 * Get invite by ID
 * @param {Object} state 
 * @param {String} code 
 */
const getInvite = (state, code) => {
  if (!state.inviteByCode[code]) {
    return {
      touched: false,
      loading: false,
      error: null,
      data: null
    };
  }

  return state.inviteByCode[code];
}

/**
 * Mutate invite state
 * @param {Object} state 
 * @param {String} code 
 * @param {Object} data 
 */
const setInvite = (state, code, data) => {
  var newState = { ...state };
  newState.inviteByCode[code] = Object.assign(getInvite(state, code), data);
  return newState;
}

function invitationReducer (state = DEFAULT_STATE, action) {
  if (action.type === 'INVITE_FETCH_REQUEST') {
    return setInvite(state, action.payload.id, {
      touched: true,
      loading: true,
      error: false,
      data: null
    });
  }

  if (action.type === 'INVITATION.FETCH_OK') {
    return setInvite(state, action.payload.id, {
      touched: true,
      loading: false,
      error: false,
      data: { ...action.payload.data }
    });
  }

  if (action.type === 'INVITATION.FETCH_ERROR') {
    return setInvite(state, action.payload.id, {
      touched: true,
      loading: false,
      error: action.payload.error,
      data: null
    });
  }

  if (action.type === 'INVITE_FETCH_RESPONSE') {
    return setInvite(state, action.payload.id, {
      ...action.payload.data
    });
  }

  return state;
}

export default invitationReducer;