import React from 'react';
import { Box as SuperBox, Container, ContainerOverlay, LogoHolder, FormTitle, FormContainer, FormBody, FormFooter } from './components';
import './styles.css';

const ContainerBox = (props) => {
  return (
    <Container className="auth-screen">
      <ContainerOverlay>
        <SuperBox className="animated bounceInDown">
          <FormContainer>{props.children}</FormContainer>
          <LogoHolder>
            <a href="/#/">
              <img src="/assets/logo.png" alt="Logo do ESL" />
            </a>
          </LogoHolder>
        </SuperBox>
      </ContainerOverlay>
    </Container>
  )
};

export const Title = FormTitle;
export const Box = ContainerBox;
export const Footer = FormFooter;
export const Body = FormBody;

export default ContainerBox;