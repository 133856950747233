import React from 'react';
import { connect } from 'react-redux';
import { Hub } from 'aws-amplify';

import Controlled from './Controlled';
import withUser from '../withUser';

const User = (props) => {
  const renderLoading = () => {
    return (
      <span>Carregando...</span>
    );
  }

  const handleSignOut = (e) => {
    Hub.dispatch('sign_out', {});
  }

  if (props.user.loading) {
    return renderLoading();
  }

  if (props.user.connected === false) {
    return (<span>Ainda não conectado</span>);
  }

  if (!props.user.data) {
    return renderLoading();
  }

  return (<Controlled name={props.user.data.name} email={props.user.data.email} onSignOut={handleSignOut} />)
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const withRedux = connect(mapStateToProps);
export default withUser(withRedux(User));