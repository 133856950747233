import React from 'react';
import ErrorMessage from '../ErrorSpan';

const TextField = (props) => {
  var classList = ['form-item'];

  if (props.error && props.error.length > 0) {
    classList.push('has-error');
  }

  const elementProps = {
    ...props,
    name: props.name || props.key,
    type: props.type || 'text'
  };

  return (
    <div className={classList.join(' ')}>
      <div className="form-group">
        <label className="form-label">{props.label}</label>
        <div className="form-control">
          <input {...elementProps} />
        </div>
      </div>
      { props.error && props.error.length > 0 && (<ErrorMessage message={props.error} />) }
    </div>
  )
}

export default TextField;