import {
  validateForm,
  validateEmailAddress,
  validateMatch,
  validateNonNull,
  validateCharLength,
  combineValidators
} from '../helpers/validator';

const signUpValidator = validateForm({
  'name': validateNonNull('name'),
  'email': combineValidators([
    validateNonNull('email'),
    validateEmailAddress('email')
  ]),
  'password': combineValidators([
    validateNonNull('password'),
    validateCharLength('password', 6)
  ]),
  'passwordConfirm': combineValidators([
    validateNonNull('passwordConfirm'),
    validateMatch('passwordConfirm', 'password')
  ])
});

export default signUpValidator;