import { Auth, Hub } from 'aws-amplify';

export default async ({ payload }) => {
  var response = {
    email: payload.email, // Identitification
    error: null,
    data: null
  };

  try {
    await Auth.confirmSignUp(payload.email, payload.code);
  } catch (e) {
    response.error = e;
  }

  Hub.dispatch('submit_email_confirmation_code_response', response);
}