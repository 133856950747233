import { Hub } from 'aws-amplify';
import { authenticate, getUser } from '../helpers/cognito';
import session from '../shared/session';

export default async ({ payload }) => {
  const CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID;

  var signInResponsePayload = {
    request: payload,
    error: null,
    data: null
  };

  var authenticationParams = {
    clientId: CLIENT_ID,
    username: payload.email,
    password: payload.password 
  };

  console.log('sign-in', authenticationParams);

  session.setState({
    touched: true,
    fetching: true,
    authenticated: false,
    user: null
  }).emit();

  var sessionState = {
    touched: true,
    fetching: false,
    authenticated: false,
    user: null
  }

  try {
    var authentication = await authenticate(authenticationParams);

    var credentials = {
      idToken: authentication.AuthenticationResult.IdToken,
      accessToken: authentication.AuthenticationResult.AccessToken,
      refreshToken: authentication.AuthenticationResult.RefreshToken
    };

    var user = await getUser({ clientId: CLIENT_ID, accessToken: credentials.accessToken });

    signInResponsePayload.data = user;
    sessionState.fetching = false;
    sessionState.authenticated = true;
    sessionState.user = user;

    //localStorage.setItem(`refreshToken`, credentials.refreshToken); // if keep signed
    //localStorage.setItem(`accessToken`, credentials.accessToken);
    session.setCredentials(credentials);
  } catch (e) {
    signInResponsePayload.error = e;
  }

  session.setState(sessionState).emit();
  Hub.dispatch('sign_in_response', signInResponsePayload);
}