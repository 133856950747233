import React from 'react';
import PropTypes from 'prop-types';

// Components
import GeneralForm from './GeneralForm';

const SignInForm = (props) => {
  return (<GeneralForm {...props} />)
}

SignInForm.propTypes = {
  touched: PropTypes.object,
  data: PropTypes.object,
  errors: PropTypes.object,
  changeAccountButton: PropTypes.object,
  createAccountButton: PropTypes.object,
  forgotEmailButton: PropTypes.object,
  forgotPasswordButton: PropTypes.object,
  submitAccountButton: PropTypes.object,
  submitPasswordButton: PropTypes.object,
  onChange: PropTypes.func
};

export default SignInForm;