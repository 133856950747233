import { useState, useCallback, useEffect } from 'react';

import api from '../helpers/api';

const useInviteResponseSubmit = (inviteId, inviteSecret) => {
  const [ error, setError ] = useState(undefined);
  const [ data, setData ] = useState(undefined);
  const [ isTouched, setIsTouched ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const wasSubmitted = isTouched && (data || error);

  // Reset states
  useEffect(() => {
    setIsTouched(false);
    setData(undefined);
    setError(undefined);
    setIsSubmitting(false);
  }, [ inviteId ]);

  const handleSubmit = useCallback(() => {
    try {
      setIsTouched(true);
      setIsSubmitting(true);

      var answerInviteParams = {
        id: inviteId,
        code: inviteSecret,
        accept: true
      };

      api.answerInvite(answerInviteParams)
        .then((response) => {
          if (response.error) {
            setError(response.error);
          } else {
            setData(response);
          }

          setIsSubmitting(false);
        })
        .catch((err) => {
          setError(err);
          setIsSubmitting(false);
        });
    } catch (e) {
      setError(e);
      setIsSubmitting(false);
    }
  }, [ inviteId, inviteSecret ]);

  return {
    isTouched,
    isSubmitting,
    error,
    data,
    wasSubmitted,
    submit: handleSubmit
  }
}

export default useInviteResponseSubmit;