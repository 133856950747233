import React from 'react';

// Components
import Button from '../Button';
import { Title, Box } from '../Whitebox';
import { Uncontrolled as User } from '../User';
import { Warning } from './styles';

/**
 * Return permission as human friendly text
 * @param {String} key 
 * @returns {String}
 */
const getTranslatedPermission = (key) => {
  var mapping = {
    'READ': 'Somente leitura',
    'WRITE': 'Escrita',
    'ADMIN': 'Gerenciamento',
    'OWNER': 'Proprietário'
  }

  return mapping[key] || 'Permissão não reconhecida';
}

/**
 * Check whether requester is valid
 * @param {Object} props 
 * @returns {Boolean}
 */
const isValidRequester = (props) => {
  var userId = props.user.data.id;
  var requesterId = props.data.invite.data.requesterId;

  if (!requesterId) {
    return false;
  }

  if (!userId) {
    return false;
  }

  return requesterId !== userId;
}


const isValidEmail = (props) => {
  return props.user.data.email === props.data.invite.data.email;
}

const CompleteInvitationForm = ({ onSubmit, ...props }) => {
  return (
    <Box>
      <Title>Você recebeu um convite</Title>
      <span>Você foi convidado a participar do projeto "<strong>{props.data.project.data.name}</strong>"</span>
      <br/>
      <br/>

      <span>De: {props.data.requester.data.email}</span><br/>
      <span>Para: {props.data.invite.data.email}</span><br/>
      <span>Permissão: {getTranslatedPermission(props.data.invite.data.permission)}</span><br/>

      <br />
      <small><span>Conectado como:</span></small><br/>
      <User />

      <br/>
      { (!isValidRequester(props)) && <Warning>Alerta: Você não pode aceitar um convite que você mesmo criou.</Warning> }
      { (!isValidEmail(props)) && <Warning>Alerta: Você não é o destinatário deste convite.</Warning> }
      { (!props.sending && props.error) && <Warning>Erro: {props.error}</Warning> }

      <div>
        <Button onClick={onSubmit} disabled={!isValidRequester(props) || !isValidEmail(props) || props.sending}>
          { props.sending ? 'Aceitando...' : 'Aceitar convite' }
        </Button>
      </div>
    </Box>
  )
}

CompleteInvitationForm.defaultProps = {
  onSubmit: () => {}
};

export default CompleteInvitationForm;