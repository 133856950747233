import { Hub } from 'aws-amplify';
import session from '../shared/session';
import { renewAccessToken, getUser } from '../helpers/cognito';

/**
 * Normalize attributes
 * @param {Array} userAttributes
 */
function normalizeUserAttributes (userAttributes) {
  var attributes = Object.fromEntries(userAttributes.map(({ Name, Value }) => [Name, Value]));
  attributes.email_verified = attributes.email_verified === 'true';
  return attributes;
}

/**
 * Fetch user
 * @returns {Object}
 */
async function fetchUser () {
  if (!localStorage.getItem('refreshToken')) {
    return null;
  }

  var refreshToken = localStorage.getItem('refreshToken');

  var renewAccessTokenParams = {
    clientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    refreshToken
  };

  var credentials = await renewAccessToken(renewAccessTokenParams);
  var idToken = credentials.AuthenticationResult.IdToken;
  var accessToken = credentials.AuthenticationResult.AccessToken;
  var user = await getUser(accessToken);

  return {
    id: user.Username,
    info: normalizeUserAttributes(user.UserAttributes),
    idToken,
    accessToken
  }
}

export default async (e) => {
  // Emit request
  session.touched = true;
  session.fetching = true;
  session.authenticated = false;
  session.error = null;
  session.user = null;
  session.emit();

  var response = {
    touched: true,
    fetching: false,
    authenticated: false,
    error: null,
    user: null
  };

  try {
    var user = await fetchUser();

    if (user) {
      response.authenticated = true;
      response.user = {
        id: user.id,
        info: user.info
      };

      session.setCredentials({ idToken: user.idToken, accessToken: user.accessToken });
      Hub.dispatch('authentication_ok', { user: response.user });
    }
  } catch (e) {
    response.authenticated = false;
    response.error = e;
    Hub.dispatch('authentication_err', { error: e });
  }

  // Emit response
  session.touched = response.touched;
  session.fetching = response.fetching;
  session.authenticated = response.authenticated;
  session.error = response.error;
  session.user = response.user;
  session.emit();
}