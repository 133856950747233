import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Title, Footer } from '../Whitebox';
import TextField from '../TextField';
import EmailField from '../EmailField';
import Button from '../Button';

/**
 * Bind handler
 * @param {String} attribute 
 * @param {Object} form 
 * @param {Object} props 
 * @returns {Function}
 */
function bindHandler (attribute, form, props) {
  return (e) => {
    if (props.onChange) {
      var newState = { ...form, [attribute]: e.target.value };
      props.onChange(newState, attribute, e);
    }
  }
}

const styles = {
  input: {
    marginLeft: '-4px',
    marginRight: '-4px'
  }
}

const GeneralForm = (props) => {

  const dispatchSubmit = (originalEvent) => {
    if (!props.valid) {
      return false
    }

    if (props?.submitPasswordButton?.onClick) {
      props.submitPasswordButton.onClick(originalEvent);
    }

    if (props.onSubmit) {
      props.onSubmit(originalEvent);
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      dispatchSubmit(e);
    }
  }

  const handleSubmitClick = (e) => {
    dispatchSubmit(e);
  }

  const createHandleBlur = (field) => {
    return (originalEvent) => {
      if (props.onBlur) {
        props.onBlur(field, originalEvent);
      }
    }
  }

  return (
    <>
      <Title>Fazer login</Title>

      <div>
        <div style={styles.input}>
          <EmailField
            label="Email"
            type="email"
            value={props.data.email}
            error={props?.touched?.email ? props?.validation?.email : null}
            onChange={bindHandler('email', props.data, props)}
            onBlur={createHandleBlur('email')}
          />
        </div>

        <div style={styles.input}>
          <TextField
            label="Senha"
            type="password"
            value={props.data.password}
            error={props?.touched?.password ? props?.validation?.password : null}
            onChange={bindHandler('password', props.data, props)}
            onBlur={createHandleBlur('password')}
            onKeyUp={handleKeyUp}
          />
        </div>
      </div>

      <Footer>
        <Button color="primary" onClick={handleSubmitClick} disabled={props.sending || !props.valid}>
          { Boolean(props.sending) ? 'Entrando...' : 'Entrar' }
        </Button>
      </Footer>

      { props.touched && (<small style={{color: 'red'}}>{props?.error}</small>) }
    </>
  );
}

GeneralForm.propTypes = {
  touched: PropTypes.object,
  data: PropTypes.object,
  validation: PropTypes.object,
  error: PropTypes.string,
  createAccountButton: PropTypes.object,
  submitAccountButton: PropTypes.object,
  submitPasswordButton: PropTypes.object,
  forgotEmailButton: PropTypes.object
};

export default GeneralForm;