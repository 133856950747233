import React from 'react';
import { Redirect } from 'react-router';

import { Box as Layout } from '../../components/Whitebox';
import SignInForm from '../../components/SignInForm';
import RedirectUri from '../../components/RedirectUri';
import { isEmail } from 'validator';

import api  from '../../helpers/api';
import session from '../../shared/session';

import logEvent from '../../libs/log-event.js';

function getConfirmationEmailUrl (email) {
  var query = new URLSearchParams();

  query.set('email', email);
  query.set('redirect', window.location.href);

  return `/confirm?${query.toString()}`;
}

function validateForm (data) {
  var errors = [];

  if (!data.email || data.email.length === 0) {
    errors.push({ field: 'email', error: 'Campo e-mail não pode estar vazio' });
  }

  if (!data.password || data.password.length === 0) {
    errors.push({ field: 'password', error: 'Campo senha não pode estar vazio' });
  }

  if (data.email && !isEmail(data.email)) {
    errors.push({ field: 'email', error: 'Endereço de e-mail inválido' });  
  }

  return errors;
}

function getFieldsErrorsOfValidation (validationErrors) {
  return Object.fromEntries(validationErrors.map(({ field, error }) => {
    return [ field, error ]
  }));
}

const SignInPage = (props) => {
  const search = new URLSearchParams(props.location.search);

  const defaultFormData = {
    email: search.get('email'),
    password: '',
    confirmed: false
  };

  const [ touched, setTouched ] = React.useState({});
  const [ loggedIn, setLoggedIn ] = React.useState(false);
  const [ redirectUrl, setRedirectUrl ] = React.useState(search.has('redirect') ? 'custom' : 'home');
  const [ isSending, setIsSending ] = React.useState(false);
  const [ error, setError ] = React.useState(null);
  const [ formData, setFormData ] = React.useState(defaultFormData);
  const validationErrors = validateForm(formData);

  const handleChange = (form) => {
    setFormData(form);
  }

  const handleSubmit = async () => {
    setIsSending(true);

    var signInParams = {
      email: formData.email,
      password: formData.password
    };

    try {
      var response = await api.signIn(signInParams);
      logEvent('login_ok', { email: formData.email });
      localStorage.setItem('refreshToken', response.refreshToken);
      session.setCredentials(response);
      session.start();
      setLoggedIn(true);
    } catch (e) {
      logEvent('login_error', { email: formData.email, error: e.message || e.toString() });

      if (e.code && e.code === 'UserNotConfirmedException') {
        setRedirectUrl('confirm_email');
        return false;
      }

      setError(e.message);
    }

    setIsSending(false);
  }

  const handleBlur = (field) => {
    var newTouched = { ...touched };
    newTouched[field] = true;
    setTouched(newTouched);
  }

  const signInFormProps = {
    valid: validationErrors.length === 0,
    touched: touched,
    data: formData,
    sending: isSending,
    validation: getFieldsErrorsOfValidation(validationErrors),
    error: error,
    onBlur: handleBlur,
    onChange: handleChange,
    onSubmit: handleSubmit
  };

  if (loggedIn && redirectUrl === 'confirm_email') {
    return <Redirect to={getConfirmationEmailUrl(formData.email)} />
  }

  if (loggedIn && redirectUrl === 'home') {
    return (<Redirect to={'/'} />);
  }

  if (loggedIn && redirectUrl === 'custom') {
    return (<RedirectUri to={search.get('redirect')} />);
  }

  return (
    <Layout>
      <SignInForm {...signInFormProps} />
    </Layout>
  );
}

export default SignInPage;