import { CognitoIdentityServiceProvider } from 'aws-sdk';

const cognito = new CognitoIdentityServiceProvider({
  region: 'us-east-1'
});

/**
 * Cognito authenticate
 * @param {Object} params
 * @param {String} params.email
 * @param {String} params.password
 * @returns {Promise}
 */
export const authenticate = async function (params) {
  const { email, password } = params;

  const authParams = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    AuthParameters: {
      USERNAME: email,
      PASSWORD: password
    }
  };

  var response = await cognito.initiateAuth(authParams).promise();
  return response;
}

export const isConfirmed = async function (params) {
  // Code here
}

/**
 * Cognito refresh token
 * @param {Object} params 
 * @param {String} params.clientId
 * @param {String} params.refreshToken
 * @returns {Promise}
 */
export const refreshToken = async function (params) {
  const { clientId, refreshToken } = params;

  const authParams = {
    AuthFlow: 'REFRESH_TOKEN_AUTH',
    ClientId: clientId,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken
    }
  };

  var response = await cognito.initiateAuth(authParams).promise();
  return response;
}

/**
 * Cognito get user
 * @param {String} accessToken
 */
export const getUser = async function (accessToken) {
  var response = await cognito.getUser({ AccessToken: accessToken }).promise();
  return response;
}

/**
 * Check whether account exists
 * @param {Object} params 
 * @param {String} params.username
 * @param {String} params.clientId
 * @returns {Boolean} whether account exists
 */
export const isValidUser = async function (params) {
  const { username, clientId } = params;

  return new Promise((resolve) => {
    var requestParams = {
      username: username,
      clientId: clientId,
      password: '123'
    };

    authenticate(requestParams)
      .then(() => {
        resolve(true);
      })
      .catch((e) => {
        resolve(e.code !== "UserNotFoundException");
      });
  });
}

export const renewAccessToken = refreshToken;

export default { authenticate, refreshToken, renewAccessToken, getUser, isValidUser }