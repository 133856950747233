import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

const useInviteRouteMetadata = () => {
  const [ id, setId ] = useState(undefined);
  const [ code, setCode ] = useState(undefined);
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setId(params.id);
    setCode(searchParams.get('code').trim());
  }, [ params, location.search ]);

  return {
    id,
    code
  }
}

export default useInviteRouteMetadata;