import React, { useCallback, useEffect } from 'react';
import { Redirect } from 'react-router';
import qs from 'querystring';

// Components
import { Box as Layout, Title } from '../../components/Whitebox';
import Button from '../../components/Button';
import EmailDisplay from '../../components/EmailDisplay';
import RedirectUri from '../../components/RedirectUri';

// Forms
import CompleteInvitationForm from '../../components/CompleteInvitationForm';

// Hooks
import useFetchInvite from '../../hooks/useInviteFetch';
import useInviteRouteMetadata from '../../hooks/useInviteRouteMetadata';
import useSignedUser from '../../hooks/useSignedUser';
import useInviteResponseSubmit from '../../hooks/useInviteResponseSubmit';
import useProjectACL from '../../hooks/useProjectACL';

const CongratulationsPartial = ({ invite, ...props }) => {
  const [ isOpenApp, setIsOpenApp ] = React.useState(false);
  const redirectUrl = `${process.env.REACT_APP_MAP_ENDPOINT}/#/project/${invite.data.invite.data.projectId}`;

  const handleClick = () => {
    setIsOpenApp(true);
  }

  if (isOpenApp) {
    return (<RedirectUri to={redirectUrl} />);
  }

  return (
    <Layout>
      <Title>Convite aceito!</Title>
      <span>Seu convite foi aceito, agora você está participando do projeto "<strong>{invite.data.project.data.name}</strong>"</span>
      <br/>
      <br/>
      <Button onClick={handleClick}>Abrir aplicação</Button>
    </Layout>
  )
}

const UserIsNotSignedPartial = (props) => {
  const [ redirect, setRedirect ] = React.useState(null);

  function getMessage (exists) {
    if (exists) {
      return (<p>Você já possui uma conta em nossa plataforma, para continuar favor efetuar <strong>login</strong> pelo botão abaixo:</p>)
    }
  
    return (<p>Você ainda não possui uma conta no nossa plataforma, para continuar é necessário se <strong>cadastrar</strong> pelo botão abaixo:</p>);
  }

  function handleSubmit () {
    setRedirect(props.data.exists ? 'sign_in' : 'sign_up');
  }

  if (redirect === 'sign_in') {
    return (<Redirect to={`/sign-in?${qs.stringify({ email: props.data.invite.data.email, redirect: window.location.href })}`} />);
  }

  if (redirect === 'sign_up') {
    return (<Redirect to={`/sign-up?${qs.stringify({ email: props.data.invite.data.email, redirect: window.location.href })}`} />);
  }

  return (
    <Layout>
      <Title>Você recebeu um convite</Title>
      <span>Você foi convidado a participar do projeto "<strong>{props.data.project.data.name}</strong>"</span>

      <br/>
      <br/>
      { getMessage(props.data.exists) }
      <EmailDisplay>{props.data.invite.data.email}</EmailDisplay>
      
      <div>
        <Button onClick={handleSubmit}>{ props.data.exists ? 'Continuar para login' : 'Efetuar cadastro' }</Button>
      </div>
    </Layout>
  )
}

/*
const UserAddressIsNotInInvitePartial = (props) => {
  return (
    <Layout {...props}>
      <Title>Sua conta atual não é a mesma do convite</Title>
      <span>Caso tenha entrado com o usuário errado <a href="#">clique aqui</a></span>
    </Layout>
  )
}
*/

const UserIsAlreadyParticipatingPartial = ({ invite, ...props }) => {
  const redirectUrl = `${process.env.REACT_APP_MAP_ENDPOINT}/#/project/${invite.data.invite.data.projectId}`;

  useEffect(() => {
    var timeoutId = setTimeout(() => {
      window.location.href = redirectUrl;
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    }
  }, [ redirectUrl ]);
  
  const handleClick = () => {
    window.location.href = redirectUrl;
  }

  return (
    <Layout {...props}>
      <Title>Você já está participando deste projeto</Title>
      <span>Você estará sendo redirecionado automaticamente para o projeto "<strong>{invite.data.project.data.name}</strong>" dentro de alguns instantes.</span>
      <br />
      <br />

      <Button onClick={handleClick}>Ir para o projeto</Button>
    </Layout>
  );
}

const InviteIsAlreadyInUsePartial = ({ invite, ...props }) => {
  return (
    <Layout {...props}>
      <Title>Este convite já foi usado!</Title>
      <span>Se você é o destinatário deste convite e ainda não faz parte do projeto "<strong>{invite.data.project.data.name}</strong>" solicite um novo convite ao administrador do projeto.</span>
    </Layout>
  )
}

const InvitePage = () => {
  const metadata = useInviteRouteMetadata();
  const invite = useFetchInvite(metadata.id); // Description: Fetch invite data by API
  const signedUser = useSignedUser();
  const signedUserProjectACL = useProjectACL(invite?.data?.invite?.data?.projectId);
  const inviteSubmit = useInviteResponseSubmit(metadata.id, metadata.code); // Description: Send submit response to API
  const nestedErrors = [];

  if (invite.error) {
    nestedErrors.push(invite.error);
  }

  if (invite.data && invite.data.invite.error) {
    nestedErrors.push(invite.data.invite.error);
  }

  if (invite.data && invite.data.project.error) {
    nestedErrors.push(invite.data.project.error);
  }  

  const handleSubmit = useCallback(() => {
    inviteSubmit.submit(true);
  }, [ inviteSubmit ]);


  if (!metadata.code || !metadata.id) {
    return (
      <span>URL inválida!</span>
    );
  }

  if (!invite.isReady) {
    return (<span>Carregando convite...</span>);
  }

  if (nestedErrors.length > 0) {
    return (<span>Oops! parece que algo deu errado: { nestedErrors[0].toString() }</span>);
  }

  if (inviteSubmit.wasSubmitted && !inviteSubmit.error) {
    return (<CongratulationsPartial invite={invite} />);
  }

  if (signedUser.isConnected && signedUserProjectACL.isReady && signedUserProjectACL.scopes.length > 0) {
    return (<UserIsAlreadyParticipatingPartial invite={invite} />);
  }

  if (!signedUser.isConnected) {
    return (<UserIsNotSignedPartial id={metadata.id} code={metadata.code} data={invite.data} />);
  }

  if (!invite.data.invite.data.open) {
    return (<InviteIsAlreadyInUsePartial invite={invite} />);
  }

  return (
    <CompleteInvitationForm
      sending={inviteSubmit.isSubmitting}
      error={inviteSubmit.error}
      data={invite.data}
      user={signedUser}
      onSubmit={handleSubmit}
    />
  );
};

export default InvitePage;