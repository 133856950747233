import { useState, useEffect } from 'react';
import useSignedUser from './useSignedUser';

// API
import api from '../helpers/api';

const useProjectACL = (projectId) => {
  const [ lastProjectId, setLastProjectId ] = useState(null);
  const [ isFetching, setIsFetching ] = useState(false);
  const [ error, setError ] = useState(undefined);
  const [ data, setData ] = useState(undefined);
  const signedUser = useSignedUser();
  const scopes = data?.scopes || [];
  const isReady = !isFetching && (projectId === lastProjectId) && !(!projectId);

  useEffect(() => { 
    if (!projectId || projectId.length < 1) {
      return;
    }

    setError(undefined);
    setData(undefined);
    setIsFetching(true);

    api.fetchProjectACL({ projectId })
      .then((response) => {
        setData(response);
        setLastProjectId(projectId);
        setIsFetching(false);
      })
      .catch((err) => {
        setError(err);
        setLastProjectId(projectId);
        setIsFetching(false);
      });
  }, [ projectId, signedUser.isConnected ]);

  // TODO: Refresh on user changes

  return {
    projectId,
    lastProjectId,
    isReady,
    isFetching,
    error,
    data,
    scopes
  }
}

export default useProjectACL;