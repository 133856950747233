import store from '../store';

export default ({ payload }) => {
  //console.log('Session state changed', payload);

  const dispatchParams = {
    type: 'USER.SET_DATA',
    payload: {
      touched: payload.touched,
      connected: payload.authenticated,
      loading: payload.fetching,
      data: {}
    }
  };

  if (payload.user) {
    dispatchParams.payload.data.id = payload.user.id;
    dispatchParams.payload.data.name = payload.user.info.name;
    dispatchParams.payload.data.email = payload.user.info.email;
    dispatchParams.payload.data.verified = payload.user.info.email_verified;
  }

  store.dispatch(dispatchParams);
}