import { put } from 'redux-saga/effects';

function* initialize (action) {
  console.log('Initializing...');

  yield put({
    type: 'AUTH.FETCH_SESSION',
    payload: {}
  });
};

export default initialize;