import React from 'react';

const RedirectUri = (props) => {
  if (props.to) {
    window.location.href = props.to;
  }

  return (<></>);
}

export default RedirectUri;