// Polyfills
import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'core-js/es/typed-array/uint32-array';
import 'core-js/es/array/find';
import 'core-js/es/object/assign';
import 'core-js/es/object/entries';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Stylesheets
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));